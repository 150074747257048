import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { transformPostSchema } from '../util';
import HeaderMeta from '../components/HeaderMeta';

import {
  HeadingBox,
  OctopusBlueFrame,
  BlogCategoryHeading,
  BlogPostList,
  SectionGroup,
} from "@minware/ui-minware";

export const BlogAuthorTemplate = ({
  content,
  postList,
  octopus,
  slug,
}) => {
  octopus = octopus || {};
  postList = postList || [];
  const posts = postList.map(({node}) => transformPostSchema(node));
  const useSlug = slug || '/';
  const canonicalUrl = useSlug[useSlug.length - 1] === '/'
    ? useSlug.substring(0, useSlug.length - 1)
    : useSlug;

  const headerWithCanon = {
    ...content?.header,
    canonicalUrl,
  };

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={headerWithCanon}/>

      <BlogCategoryHeading
        heading={content.displayName}
        subHeadline={content.bio}
        imageLink={content.photo.childImageSharp.fluid.src}
        twitterLink={content.twitterLink}
        linkedInLink={content.linkedInLink}
      />

      <SectionGroup>
        <HeadingBox level="h1" tagLevel="h2" evenMargin={true}>
          Latest Articles
        </HeadingBox>

        <BlogPostList posts={posts}/>
      </SectionGroup>
    </Layout>
  )
};

const BlogAuthorPage = (props) => {
  const { data } = props;
  const { index, main, all } = data;

  const content = main.frontmatter;
  const postList = all.edges;
  const octopus = index.frontmatter.octopus;

  const header = {
    title: `${content.displayName}, Author at minware`,
    description: `Read the latest articles by ${content.displayName} on minware.`,
  }
  content.header = header;

  return (
    <BlogAuthorTemplate
      content={content}
      postList={postList}
      octopus={octopus}
      slug={main.fields.slug}
    />
  );
}

export default BlogAuthorPage

export const pageQuery = graphql`
  query BlogAuthorPage($id: String, $authorName: String) {
    index: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      ...OctopusFragment
    }

    main: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        authorName
        displayName
        bio
        linkedInLink
        twitterLink
        photo {
          childImageSharp {
            fluid(maxWidth: 110, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    all: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          author: {
            frontmatter: {
              authorName: {
                eq: $authorName
              }
            }
          },
          templateKey: {
            eq: "blog-post"
          }
        }
      }
    ) {
      totalCount
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`
